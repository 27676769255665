/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '../../node_modules/@pa/lib-spa/assets/scss/themes' as themes;
@import '../../node_modules/@aws-amplify/ui-angular/theme.css';
@import '../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/light.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/regular.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/brands.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/duotone.scss';

@import '../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import '../../node_modules/ag-grid-community/dist/styles/ag-theme-material.css';
@import '../../node_modules/ngx-toastr/toastr';
@import '../../node_modules/@pa/lib-spa/assets/scss/styles.scss';
@include themes.all;
@include mat.core();

$candy-app-primary: mat.define-palette(mat.$indigo-palette); //notice that functions not Namespaced

$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.success-snackbar {
    width: max-content;
    background: #62ee0d;
}

.warning-snackbar {
    background: #d78686;
}

.error-snackbar {
    width: max-content;
    background: #e30d0d;
}
.mat-mdc-dialog-container {
    background: white;
}
.warning-column {
    width: 30px !important;
    color: #673ab7 !important;
}

// Fixes an issue where the table min height is too big leading to white space
.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper {
    min-height: 46px !important;
}

.mat-mdc-card-title {
    margin-left: -10px;
}

.mat-mdc-header-cell {
    font-size: 14px;
}

.mat-mdc-radio-button ~ .mat-mdc-radio-button {
    margin-left: 16px;
}

table {
    td,
    th {
        padding: 3px 5px;
    }

    td:first-child,
    th:first-child {
        padding-left: 0 !important;
    }

    td:last-child,
    th:last-child {
        padding-right: 0 !important;
    }
}

.footer {
    font-size: small;
    color: #666;
    background-color: rgba(230, 230, 230, 0.7);
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 4px 8px;
    border-radius: 8px;
}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

.mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: 0.75;
    display: unset !important;
}

.cdk-overlay-pane {
    top: 180px !important;
    display: inline !important;
}

.theme .mat-mdc-raised-button.mat-primary:not(:disabled) {
    color: #fff;
}

.theme .mat-mdc-unelevated-button.mat-primary:not(:disabled) {
    color: #fff;
}
